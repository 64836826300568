import axios from 'axios';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCSigPfLiplD8IeZK5GzlFKyEPGB0sDURc",
    authDomain: "atomic-sled-142010.firebaseapp.com",
    databaseURL: "https://atomic-sled-142010.firebaseio.com",
    projectId: "atomic-sled-142010",
    storageBucket: "atomic-sled-142010.appspot.com",
    messagingSenderId: "112011901390",
    appId: "1:112011901390:web:717a3206b0035ea93a139f",
    measurementId: "G-LTWM5071PS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

getToken(messaging).then((currentToken) => {
    if (currentToken) {
        axios({
            url: baseUrl + '/messaging/tk',
            method: 'post',
            data: {
                token: currentToken
            },
            headers: {
                'X-CSRF-TOKEN': csrfToken,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                //console.log(response)
            })
            .catch(err => {
                console.log(err);
            });

    } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
    }
}).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
});
